/* eslint-disable import/no-cycle */
/* eslint class-methods-use-this: ["error", { "exceptMethods": ["deleteUser", "checkIfUserExists", "hasTollApp"],  }] */
import { action, makeObservable, observable, runInAction } from 'mobx';
import BaseStore from './base-stores/BaseStore';
import { RootStore } from './RootStore';
import {
  AccountInfo,
  AccountInfoLight,
  AccountService,
  NotificationService,
  Person,
  PersonDetails,
  PersonService,
  UserRole
} from '../swagger/api';
import { webAppIsFullyLoaded } from '../utils/wrapperAppFunctions';

export default class UserStore extends BaseStore {
  // selectedAccountId is made for commercial use in case a user changes account, the selected account will be saved in browser session.
  // currentAccount holds the value of the active account object.

  selectedAccountId: string | null = sessionStorage.getItem('selectedAccountId');

  currentAccount?: AccountInfo;

  person: Person | undefined = undefined;

  accounts: AccountInfoLight[] = [];

  accountSearchValue: string = '';

  accountPageNumber: number = 1;

  accountFilteredCount?: number = 0;

  defaultAccountId?: string;

  isSelectAccountShown: boolean = false;

  setSelectedAccountId = (accountId: string) => {
    this.selectedAccountId = accountId;
    sessionStorage.setItem('selectedAccountId', accountId);
  };

  changePassword = () => {
    const promise = PersonService.requestChangePassword({ email: this.person?.email });
    return promise;
  };

  loadCurrentUser = async () => {
    this.rootStore.loadingStore.enableGlobalSpinner();
    const promise = PersonService.getCurrentPerson();
    await promise.then(async (result) => {
      this.person = result;
      this.setIsSelectAccountShown();
      this.defaultAccountId = result.defaultAccountId;
      // if the user is not private and the user does not have a default account already, the user navigates to "vaelg-konto" / ChooseDefaultAccount to select an account.
      // if user is private, selectedAccountId is never set OR user only have 1 account
      if (
        result.role === UserRole.PrivatePerson ||
        (result.accountIds &&
          result.accountIds.length === 1 &&
          result.role !== UserRole.ProductAccess) ||
        result.role === UserRole.ProductAccess
      ) {
        const accountId = result.accountIds![0];
        await this.loadCurrentAccount(accountId).then(() => {
          webAppIsFullyLoaded();
          this.rootStore.loadingStore.disableGlobalSpinner();
        });
      }
      // if user has a default accountId and selectedAccount is null the default account is loaded
      if (result.defaultAccountId && this.selectedAccountId === null) {
        const accountId = result.defaultAccountId;
        await this.loadCurrentAccount(accountId).then(() => {
          this.rootStore.loadingStore.disableGlobalSpinner();
        });
      }

      // if a selected accountId has been set, that is the account to be loaded
      if (this.selectedAccountId) {
        await this.loadCurrentAccount(this.selectedAccountId).then(() => {
          this.rootStore.loadingStore.disableGlobalSpinner();
        });
      }
      // If commmercial user is on page 'opret-kunde' or select account.
      else {
        this.rootStore.loadingStore.disableGlobalSpinner();
      }
    });
    return promise;
  };

  loadCurrentAccount = (accountId?: string) => {
    const promise = AccountService.getAccountInfoById({
      accountId: accountId || this.currentAccount?.id!
    });
    promise.then((account: AccountInfo) => {
      this.currentAccount = account;
    });
    return promise;
  };

  getAccountInfoLight = () => {
    const requestParams: {
      personId: string;
      searchString: string;
      pageNumber?: number;
    } = {
      personId: this.rootStore.userStore.person?.id!,
      searchString: this.accountSearchValue,
      pageNumber: this.accountPageNumber
    };

    const promise = PersonService.getAccountInfoLight(requestParams).then((result) => {
      runInAction(() => {
        if (this.accountPageNumber === 1) this.accounts = result.paginatedItems!;
        else this.accounts.push(...result.paginatedItems!);
        this.accountFilteredCount = result.filteredCount;
      });
    });
    return promise;
  };

  getAccountInfoLightBySearching = () => {
    this.accounts = [];
    this.accountPageNumber = 1;

    const requestParams: {
      personId: string;
      searchString: string;
    } = {
      personId: this.rootStore.userStore.person?.id!,
      searchString: this.accountSearchValue
    };

    const promise = PersonService.getAccountInfoLight(requestParams).then((result) => {
      this.accounts = result.paginatedItems!;
      this.accountFilteredCount = result.filteredCount;
    });
    return promise;
  };

  checkIfUserExists = (email: string) => {
    const promise = PersonService.userExistsByEmail({ email });
    return promise;
  };

  hasTollApp = (accountId: string) => {
    const promise = NotificationService.hasTollApp({ accountId });
    return promise;
  };

  setSearchValue = (searchValue: string) => {
    this.accountSearchValue = searchValue;
  };

  setPageNumber = (searchValue: number) => {
    this.accountPageNumber = searchValue;
  };

  chooseDefaultAccount = async (accountId: string) => {
    await PersonService.chooseDefaultAccount({ accountId });
    this.defaultAccountId = accountId;
  };

  deleteUser = (personId: string, accountId: string) => {
    const promise = PersonService.deletePersonById({ accountId, personId });
    return promise;
  };

  updateCurrentUserDetails = (body: PersonDetails) => {
    // eslint-disable-next-line no-param-reassign
    const accountId =
      this.person?.role === UserRole.ProductAccess
        ? this.person.accountIds![0]
        : this.currentAccount?.id!;
    const promise = PersonService.updatePersonDetails({
      accountId,
      personId: this.person?.id!,
      body
    });
    promise.then(() => {
      this.loadCurrentUser();
    });
    return promise;
  };

  setPublicConsent = (isConsenting: boolean) => {
    const promise = PersonService.updateNewsLetterConsent({
      accountId: this.currentAccount?.id!,
      isConsenting
    }).then(() => {
      this.loadCurrentUser();
    });
    return promise;
  };

  setCommercialConsent = async (isConsenting: boolean) => {
    this.rootStore.loadingStore.enableGlobalSpinner();
    await PersonService.updateNewsLetterConsent({
      accountId: this.currentAccount?.id!,
      isConsenting
    });
    await this.loadCurrentUser();
    this.rootStore.loadingStore.disableGlobalSpinner();
  };

  setLastTimeConsentReminderWasShown = () => {
    PersonService.updateLastTimeConsentWasShown({
      accountId: this.currentAccount?.id!
    }).then(() => {
      this.loadCurrentUser();
    });
  };

  setIsSelectAccountShown = () => {
    this.isSelectAccountShown =
      (this.person?.defaultAccountId === null || this.person?.defaultAccountId === undefined) &&
      !!this.person?.accountIds &&
      this.person.accountIds.length > 1 &&
      !this.rootStore.manageUserAccessStore.isPrivateCustomer() &&
      this.person?.role !== UserRole.ProductAccess &&
      this.person.signUpInProgress === false &&
      !this.selectedAccountId;
  };

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this, {
      accounts: observable,
      accountSearchValue: observable,
      currentAccount: observable,
      person: observable,
      selectedAccountId: observable,
      accountFilteredCount: observable,
      isSelectAccountShown: observable,
      changePassword: action,
      deleteUser: action,
      getAccountInfoLight: action,
      setSearchValue: action,
      chooseDefaultAccount: action,
      hasTollApp: action,
      setPageNumber: action,
      loadCurrentAccount: action,
      loadCurrentUser: action,
      updateCurrentUserDetails: action,
      setPublicConsent: action,
      setCommercialConsent: action,
      setLastTimeConsentReminderWasShown: action,
      setSelectedAccountId: action,
      setIsSelectAccountShown: action
    });
  }
}
